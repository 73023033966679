import React from 'react';
import './App.css';
import BackgroundImage from './Image/BackgroundImage.png';
import FEAImage from './Image/FEA.png';
import CFDImage from './Image/CFD.png';
import PAImage from './Image/ProjectAnalysis.gif';
import DNDImage from './Image/DesignAndDevelopment.png';
import MDImage from './Image/MethodologyDevelopment1.png';
import PTMImage from './Image/PhysicalTestMatching.gif';
import LogoHydra from './Logo/LogoHydra.png';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';

// const WindowWidth = window.innerWidth;
// const WindowHeight = window.innerHeight;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedFEA: false,
      enableButtonFEA: true,
      expandedCFD: false,
      enableButtonCFD: true,
      expandedPA: false,
      enableButtonPA: true,
      expandedDND: false,
      enableButtonDND: true,
      expandedPTM: false,
      enableButtonPTM: true,
      expandedMD: false,
      enableButtonMD: true,

      activeButtonConsultation: true,
      activeButtonProposal: false,
      activeButtonCommunication: false,
      activeButtonExecution: false,
      activeButtonDeliverables: false,
      activeButtonNextsteps: false,

      isOpenModal: false,

      isShowCatalog: false,

      windowWidth: window.innerWidth,
      isThuongMobile: window.innerWidth <= 413,
    };
    this.modalRef = React.createRef(); // Tham chiếu đến ModalContent
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick);
    window.addEventListener("resize", this.updateScreenSize);
    this.setState({ isThuongMobile: window.innerWidth <= 413 });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("resize", this.updateScreenSize);
  }

  updateScreenSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      isThuongMobile: window.innerWidth <= 413, // Condition show mobile screen
    });
  };

  handleButtonReadMoreFEA = () => {
    this.setState(prevState => ({
      expandedFEA: !prevState.expandedFEA,
      enableButtonFEA: !prevState.enableButtonFEA
    }));
  }

  handleButtonReadMoreCFD = () => {
    this.setState(prevState => ({
      expandedCFD: !prevState.expandedCFD,
      enableButtonCFD: !prevState.enableButtonCFD
    }));
  }

  handleButtonReadMorePA = () => {
    this.setState(prevState => ({
      expandedPA: !prevState.expandedPA,
      enableButtonPA: !prevState.enableButtonPA
    }));
  }

  handleButtonReadMoreDND = () => {
    this.setState(prevState => ({
      expandedDND: !prevState.expandedDND,
      enableButtonDND: !prevState.enableButtonDND
    }));
  }

  handleButtonReadMorePTM = () => {
    this.setState(prevState => ({
      expandedPTM: !prevState.expandedPTM,
      enableButtonPTM: !prevState.enableButtonPTM
    }));
  }

  handleButtonReadMoreMD = () => {
    this.setState(prevState => ({
      expandedMD: !prevState.expandedMD,
      enableButtonMD: !prevState.enableButtonMD
    }));
  }

  handleButtonConsultation = () => {
    this.setState({ activeButtonConsultation: true })
    this.setState({ activeButtonProposal: false })
    this.setState({ activeButtonCommunication: false })
    this.setState({ activeButtonExecution: false })
    this.setState({ activeButtonDeliverables: false })
    this.setState({ activeButtonNextsteps: false })
  }

  handleButtonProposal = () => {
    this.setState({ activeButtonConsultation: false })
    this.setState({ activeButtonProposal: true })
    this.setState({ activeButtonCommunication: false })
    this.setState({ activeButtonExecution: false })
    this.setState({ activeButtonDeliverables: false })
    this.setState({ activeButtonNextsteps: false })
  }

  handleButtonCommunication = () => {
    this.setState({ activeButtonConsultation: false })
    this.setState({ activeButtonProposal: false })
    this.setState({ activeButtonCommunication: true })
    this.setState({ activeButtonExecution: false })
    this.setState({ activeButtonDeliverables: false })
    this.setState({ activeButtonNextsteps: false })
  }

  handleButtonExecution = () => {
    this.setState({ activeButtonConsultation: false })
    this.setState({ activeButtonProposal: false })
    this.setState({ activeButtonCommunication: false })
    this.setState({ activeButtonExecution: true })
    this.setState({ activeButtonDeliverables: false })
    this.setState({ activeButtonNextsteps: false })
  }

  handleButtonDeliverables = () => {
    this.setState({ activeButtonConsultation: false })
    this.setState({ activeButtonProposal: false })
    this.setState({ activeButtonCommunication: false })
    this.setState({ activeButtonExecution: false })
    this.setState({ activeButtonDeliverables: true })
    this.setState({ activeButtonNextsteps: false })
  }

  handleButtonNextsteps = () => {
    this.setState({ activeButtonConsultation: false })
    this.setState({ activeButtonProposal: false })
    this.setState({ activeButtonCommunication: false })
    this.setState({ activeButtonExecution: false })
    this.setState({ activeButtonDeliverables: false })
    this.setState({ activeButtonNextsteps: true })
  }

  handleContactButton = () => {
    window.location.href = "https://info.hydra-cyborg.com";
  };

  handleOutsideClick = (event) => {
    // Kiểm tra xem click có xảy ra bên ngoài ModalContent không và modal đang mở
    if (this.modalRef.current && !this.modalRef.current.contains(event.target) && this.state.isOpenModal) {
      this.setState({ isOpenModal: false }); // Đóng modal
    }
  };

  handleShowCatalog = () => {
    this.setState((prevState) => ({ isShowCatalog: !prevState.isShowCatalog }));
  };

  render() {
    console.log('isOpenModal:', this.state.isOpenModal);
    return (
      <div className="Container">
        <img src={"https://hydra-cyborg.com/API/data/image/ImageMaintenance.png"} className="Maintenance" alt="Maintenance"/>
      </div >
    );
  }
}